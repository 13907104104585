// eslint-disable-next-line import/no-named-as-default
import AWS from 'aws-sdk'
// eslint-disable-next-line import/no-named-as-default
import { Alert } from 'rsuite'
import api from './_http'
// eslint-disable-next-line import/no-cycle
import { getLive, getSync, setLive, setSession, setStorage, setSync } from '../utils/Storken'
import { Router } from '../../server/routes'
import { statusData } from '../components/Modules/Admin/Orders/data'


// eslint-disable-next-line no-unused-vars
const { window } = global
const s3 = new AWS.S3({
  accessKeyId:'AKIAQHZZZJ6AB4TSEYX7',
  secretAccessKey: '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'
})
const uploadVideo = (file) => {
  console.log('video bulundu yükleniyor')
  const params = {
    Bucket: 'svgl',
    Key: file.name,
    Expires: 500,
    ContentType: file.type,
    Body: file
  }
  s3.upload(params, (err, data) => {
    console.log('video şu adreste', data.Location)
    onChanger('videos', { data_url: data.Location })
    console.log('uploadedFiles sonra', getSync('editingobject'))
    setSync('uploadStatus', true)
  })
}
export const UploadFiles = () => {
  const uploadedFiles = []
  const { photos, videos } = getSync('editingobject')
  console.log('videos', videos)
  const onlyFiles = photos.filter(i => i.file)
  const onlyPhotos = photos.filter(i => !i.file)
  console.log('onlyFiles', onlyFiles)
  console.log('onlyPhotos', onlyPhotos)
  // eslint-disable-next-line array-callback-return
  if (onlyFiles.length === 0) {
    console.log('fotoğraflar yüklü iptal')
    onChanger('photos', onlyPhotos)
    console.log('uploadedFiles sonra', getSync('editingobject'))
    if (videos.file) {
      uploadVideo(videos.file)
    } else {
      console.log('video da bulunamadı kapat')
      setSync('uploadStatus', true)
    }
  } else {
    // eslint-disable-next-line array-callback-return
    onlyFiles.map(({ file }) => {
      const params = {
        Bucket: 'svgl',
        Key: file.name,
        Expires: 500,
        ContentType: file.type,
        Body: file
      }
      s3.upload(params, (err, data) => {
        if (err) throw err
        console.log(`File uploaded successfully. ${data.Location}`)
        uploadedFiles.push({ data_url:data.Location })
        if (uploadedFiles.length === onlyFiles.length) {
          uploadedFiles.splice(0, 0, ...onlyPhotos)
          console.log('uploadedFiles önce', uploadedFiles)
          // eslint-disable-next-line no-use-before-define
          onChanger('photos', uploadedFiles)
          console.log('uploadedFiles sonra', getSync('editingobject'))
          if (videos && videos.file) {
            uploadVideo(videos.file)
          } else {
            console.log('video bulunamadı kapat')
            setSync('uploadStatus', true)
          }
        }
      })
    })
  }
}
export const removeComment = _id => api.get(`/deletecomm/${_id}`)
export const getUsersComments = active => api.get(`/getallcomm/${active}`)
export const updateComment = (id, active) => api.post('/updatecomm', { id, active })
export const getOrders = () => api.get('/getorders').then(resp => resp.data.orders)

export const getTrackOrders = data => api.get(`/gettrackorders/${data}`)

export const getSube = data => api.get(`/getsubetel/${data}`)

export const setCargoOperation = data => api.post('/setcargoperation', data)
export const getUpList = () => api.get('/getuplist')

export const setCargoUpped = cargoKey => api.post('/setcargoupped', { cargoKey })
export const getCategories = () => api.get('/getcategories').then(resp => (resp.data))
export const addCategory = data => api.post('/createcategory', data)
export const getProducts = () => api.get('/getproducts').then(resp => (resp.data.products))
export const getSameProd = id => api.get(`/findsameprod/${id}`).then(resp => resp.data)
export const deleteProduct = data => api.post('/deleteprod', data)
export const currentOrder = () => api.get('/currentorder').then(resp => resp.data.order)
export const getDates = data => api.get(`/dates/${data}`).then(resp => resp.data.orders)
export const getDatesPromise = data => api.get(`/dates/${data}`)
export const getMonthlyOrders = data => api.get(`/getmonthlyorders/${data}`).then(({ data }) => data)
export const getMonthlyOrdersPrimise = data => api.get(`/getmonthlyorders/${data}`)
export const addGift = data => api.post('/addgift', data).then(resp => setLive('allgifts', [...getLive('allgifts'), resp.data]))
export const getGifts = () => api.get('/getgifts').then(resp => resp.data)
export const getAllGifts = () => api.get('/getallgifts').then(resp => resp.data)

export const getOffers = () => api.get('/getoffers').then(resp => resp.data)
export const getOrderWPhone = phone => api.get(`/getorderwphone/${phone}`)
export const deleteGift = data => api.post('/deletegift', data).then((resp) => {
  setLive('allgifts', [...getLive('allgifts')].filter(e => e?._id !== data?._id))
})
export const getTrackUrl = cargoKey => api.get(`/querycargo/${cargoKey}`)
export const getPttCargoInfo = cargoKey => api.get(`/getpttcargoinfo/${cargoKey}`)
export const getCargoInfo = trackingNumber => api.get(`/getaraskargoinfo/${trackingNumber}`)
export const getArasCargoWay = trackingNumber => api.get(`/getaraskargoway/${trackingNumber}`)

// eslint-disable-next-line consistent-return
export const createProduct = () => api.post('/createproduct', getSync('editingobject'))
export const updateProduct = () => api.post('/updateproduct', getSync('editingobject'))
const getBarcode = cargoKey => api.get(`/getbarcode/${cargoKey}`)
const getCargoBarcodeInfo = cargoKey => api.get(`/getcargobarcodeinfo/${cargoKey}`)


export const onChanger = (key, value) => {
  const nextData = Object.assign({}, getSync('editingobject'))
  nextData[key] = value
  setSync('editingobject', nextData)
  console.log('data-->', getSync('editingobject'))
}
export const selectSingleVideo = (video, onChange) => {
  console.log('dosya bilgileri-->', video)
  onChange('videos', video)
}
export const selectMultiPic = (picture, onChange) => {
  console.log('dosya bilgileri-->', picture)
  const picarray = []
  // eslint-disable-next-line array-callback-return,camelcase
  picture.map((e) => {
    picarray.push(e)
  })
  onChange('photos', picarray)
}
export const selectSinglePic = (picture, onChange) => {
  if (picture.length > 0) {
    const okuyucu = new FileReader()
    okuyucu.readAsDataURL(picture[0])
    okuyucu.onload = () => {
      console.log('görsel-->', okuyucu.result)
      onChange('photo', okuyucu.result)
    }
  }
}
export const openToEnvanter = (keys) => {
  const orders = [...getLive('orders')]
  console.log('keys', keys)
  const prodArr = []
  const filtered = orders.filter(({ _id }) => keys.find(key => key === _id))
  console.log('filteredx', filtered)
  filtered.map(({ products }) => {
    products.map((product) => {
      const index = prodArr.findIndex(({ _id, size }) => _id === product._id && size === product.packsize.size)
      if (index > -1) {
        prodArr[index].quantity += product.quantity
      } else {
        const obj = {
          _id:product._id,
          quantity:product.quantity,
          name:product.name,
          size:product.packsize.size
        }
        prodArr.push(obj)
      }
    })
  })
  console.log('filtered', filtered)
  console.log('prodArr', prodArr)
  setSync('envanterArr', prodArr)
  setSync('envanterModal', true)
}

export const cancelCargoFromKey = async (id) => {
  console.log('order id', id)
  const resp = await api.post('/cancelcargo', { id })
  console.log('cargo cancel resp', resp.data)
  if (!resp?.data?.err) {
    Alert.warning('Kargo iptal edildi')
    const { order } = resp?.data
    const orders = [...getLive('orders')]
    const orderIndex = orders.findIndex(({ _id }) => _id === order?._id)
    orders[orderIndex] = order
    setLive('orders', [])
    setLive('orders', orders)
    return order
  }
}
export const selectSube = async (id, key, name) => {
  console.log('id', id)
  console.log('key', key)
  const resp = await api.post('/selectsube', { id, key })
  console.log('cargo select sube resp', resp.data)
  if (!resp?.data?.err) {
    Alert.warning(`${name} şubesi seçildi`)
    const { order } = resp?.data
    const orders = [...getLive('orders')]
    const orderIndex = orders.findIndex(({ _id }) => _id === order?._id)
    orders[orderIndex] = order
    setLive('orders', [])
    setLive('orders', orders)
    return order
  }
}
function convertToASCIIAndUpperCase(str) {
  const turkishMap = {
    ç: 'c',
    Ç: 'C',
    ğ: 'g',
    Ğ: 'G',
    ı: 'i',
    I: 'I',
    İ: 'I',
    ö: 'o',
    Ö: 'O',
    ş: 's',
    Ş: 'S',
    ü: 'u',
    Ü: 'U'
  }
  return str.split('').map(char => turkishMap[char] || char).join('').toUpperCase()
}
async function getArasZpl(cargo) {
  if (!cargo.sube || cargo.sube === 'OL241085') {
    if (cargo?.zpl) {
      printBarcode(cargo?.zpl)
    } else {
      const zplResponse = await getBarcode(cargo.cargoKey)
      console.log(zplResponse.data)
      const zpl = zplResponse.data
      if (zpl.err) {
        Alert.error(zpl.msg, 5000)
      } else {
        printBarcode(zpl)
      }
    }
  } else {
    const zplResponse = await getBarcode(cargo.cargoKey)
    console.log(zplResponse.data)
    const zpl = zplResponse.data
    if (zpl.err) {
      Alert.error(zpl.msg, 5000)
    } else {
      const cargoInfoResp = await getCargoBarcodeInfo(cargo.cargoKey)
      const cargoInfo = cargoInfoResp.data
      console.log(cargoInfo)
      const { sube } = cargo
      const subeName = sube === 'DY241085' ? 'ANKARA'
        : sube === 'KD241085' ? 'AFYON'
          : sube === 'OK241085' ? 'İSTANBUL'
            : sube === 'EK241085' ? 'İZMİR' : 'OLTU'

      const zplData = `^XA^PW799^LL0799^LS0^FWB^FO40,10^GB750,800,3^FS  ; Dış çerçeve (1000x800 boyutlar, biraz aşağıda)^FO9,260^A0,40,40^FDKOYDEN GELSIN^FS^FO540,470^BY3,2,100^BCB,100,Y,N,N^FD>;${cargoInfo.IntegrationCode}^FS  ; İlk barkod^FO50,90^BY5,2,50^BCB,100,Y,N,N^FD${cargoInfo.PieceDetails.PieceDetail[0].BarcodeNumber}^FS^FO200,730^A0,30,30^FDALICI^FS^FO250,90^A0,60,60^FB705,3,0,L^FD${convertToASCIIAndUpperCase(cargoInfo.ReceiverName)}^FS^FO350,100^A0,30,30^FB705,3,0,L^FD${convertToASCIIAndUpperCase(cargoInfo.ReceiverAddress)}^FS^FO500,90^FB740,10,0,R^A0,30,30^FD${convertToASCIIAndUpperCase(cargoInfo.ReceiverTownName)} / ${convertToASCIIAndUpperCase(cargoInfo.ReceiverCityName)}^FS  ; Sağdan hizalanmış metin^FO310,10^GB3,800,3^FS  ; Dikey ayırıcı çizgi^FO700,730^A0,30,30^FDDESI:${cargoInfo.PieceDetails.PieceDetail[0].Weight}^FS^FO530,10^GB3,800,3^FS  ; Dikey ayırıcı çizgi^FO750,540^A0,40,40^FDSUBE:${convertToASCIIAndUpperCase(subeName)}^FS^FO560,0^BQN,2,10^FDQA,${cargoInfo.IntegrationCode}^FS  ; QR kod^XZ`
      printBarcode(zplData)
    }
  }
}
async function getPttZpl(cargo) {
  const zplCode = await api.get(`/getpttzpl/${cargo.cargoKey}`)
  console.log('ptt zpl', zplCode.data)
  printBarcode(zplCode.data)
}
export const getZPL = async (cargo) => {
  if (cargo.cargoType === 'ptt') {
    getPttZpl(cargo)
  } else if (cargo.cargoType === 'aras') {
    getArasZpl(cargo)
  }
  console.log('cargo', cargo)
}
export const printBarcode = async (data) => {
  console.log('yazdırılacak zpl', data)

  try {
    const resp = await fetch('http://localhost:9100/default')
    const bodyText = await resp.text()
    console.log('body', bodyText)
    // Tüm satırları ayıklamak için RegExp kullanma
    const regex = /([^\n]+): ([^\n]+)/g
    let match
    const jsonBody = {}

    // RegExp ile tüm eşleşmeleri bul
    while ((match = regex.exec(bodyText)) !== null) {
      // İlk eşleşme, anahtar; ikinci eşleşme ise değer olacak şekilde JSON oluştur
      jsonBody[match[1].trim().toLowerCase()] = match[2]
    }
    const device = {
      connection:jsonBody.connection,
      deviceType:jsonBody.type,
      manufacturer:jsonBody.manufacturer,
      name:jsonBody.name,
      provider:jsonBody.provider,
      uid:jsonBody.id,
      version:0
    }
    console.log('body', jsonBody)
    const response = await fetch('http://localhost:9100/write', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8'
      },
      body: JSON.stringify({ data, device })
    })
    console.log(response)
  } catch (error) {
    throw new Error(error)
  }
}
export const cargoControl = async (keys) => {
  setSync('adminLoadAnim', true)
  const orders = [...getLive('orders')]
  const onCargo = orders.filter(({ _id, cargo }) => keys.find(key => key === _id) && cargo?.cargoKey)
  try {
    // Use for-loop instead of map to introduce a delay
    for (const order of onCargo) {
      if (order?.cargo?.cargoKey) {
        const index = onCargo.indexOf(order)
        try {
          if (order?.cargo?.cargoType === 'aras') {
            const response = await getCargoInfo(order.cargo.trackingNumber)
            const cargo = response?.data?.Cargo
            let durum
            if (Array.isArray(cargo)) {
              durum = cargo[0]?.DURUMU
            } else {
              durum = cargo?.DURUMU
            }
            setSync('adminLoadMsg', `${index + 1}: ${order?.delivery?.name } ${durum}`)
            if (durum === 'ÇIKIŞ ŞUBESİNDE - NORMAL' || durum === 'YOLDA - NORMAL') {
              const leveledOrder = await api.post('setlevel', { orderID: order._id, level: 5 })
              console.log('leveledOrder', leveledOrder)
            } else if (durum.includes('TESLİM EDİLDİ')) {
              const leveledOrder = await api.post('setlevel', { orderID: order._id, level: 6 })
              console.log('leveledOrder', leveledOrder)
            } else if (durum.includes('İADE') || durum.includes('YÖNLENDİRİLDİ')) {
              await api.post('setlevel', { orderID: order._id, level: -2 })
            }
          } else if (order?.cargo?.cargoType === 'ptt') {
            const response = await getPttCargoInfo(order.cargo.cargoKey)
            console.log(response.data)
            if (Array.isArray(response?.data?.dongu)) {
              const durum = response?.data?.dongu[0]?.ISLEM
              setSync('adminLoadMsg', `${index + 1}: ${order?.delivery?.name } ${durum}`)
              if (durum?.includes('Kabul Edildi') || durum?.includes('Sevk Edildi')) {
                const leveledOrder = await api.post('setlevel', { orderID: order._id, level: 5 })
                console.log('leveledOrder', leveledOrder)
              } else if (durum.includes('Teslim Edildi')) {
                const leveledOrder = await api.post('setlevel', { orderID: order._id, level: 6 })
                console.log('leveledOrder', leveledOrder)
              }
            }
          } else {
            const result = await getTrackUrl(order.cargo.cargoKey)
            console.log('result', result?.data)
            const message = result?.data?.shippingDeliveryDetailVO?.operationMessage?._text
            const reject = result?.data?.shippingDeliveryDetailVO?.shippingDeliveryItemDetailVO?.rejectReasonExplanation?._text
            const cargoEventExplanation = result?.data?.shippingDeliveryDetailVO?.shippingDeliveryItemDetailVO?.cargoEventExplanation?._text
            const rejectStatusExplanation = result?.data?.shippingDeliveryDetailVO?.shippingDeliveryItemDetailVO?.rejectStatusExplanation?._text
            console.log('message', message)
            console.log('reason', reject)
            setSync('adminLoadMsg', `${index + 1}: ${order?.delivery?.name } ${message}`)

            if (message === 'Kargo teslim edilmiştir.') {
              const leveledOrder = await api.post('setlevel', { orderID: order._id, level: 6 })
              console.log('leveledOrder', leveledOrder)
            }
            if (cargoEventExplanation === 'Kargo Yüklendi' || cargoEventExplanation === 'Kargo İndirildi') {
              const leveledOrder = await api.post('setlevel', { orderID: order._id, level: 5 })
              console.log('leveledOrder', leveledOrder)
            }
            if (rejectStatusExplanation === 'İade Yapıldı') {
              const leveledOrder = await api.post('setlevel', { orderID: order._id, level: -2 })
              console.log('leveledOrder', leveledOrder)
            }
            if (reject) {
              const arr = [...getSync('rejectedCargo')]
              arr.push(order)
              setSync('rejectedCargo', arr)
            }
          }
        } catch (err) {
          console.log('Error:', err)
        }
        await new Promise(resolve => setTimeout(resolve, 100))
      }
    }
    console.log('All orders processed')
  } finally {
    setSync('adminLoadAnim', false)
    setLive('orders', [])
    const orsd = await api.get('/getorders')
    console.log('ords', orsd)
    setLive('orders', orsd.data.orders)
  }
}
export const createFatura = async (keys) => {
  console.log('fatura keys', keys)
  setSync('adminLoadAnim', true)
  try {
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index]

      try {
        // Daha önceki Promise.all yapısının yerine, tek tek istekleri sırayla gerçekleştiriyoruz.
        await new Promise(resolve => setTimeout(resolve, index * 1000))

        const response = await api.post('/createfatura', { id: key })
        console.log('response', response.data)
        if (response.data.invo) {
          setSync('adminLoadMsg', `${index + 1} numaralı ${response.data.date} tarihli fatura oluşturuldu.`)
        } else {
          setSync('adminLoadMsg', `${index + 1} numaralı ${response.data.id} ${response.data.msg}`)
        }
      } catch (error) {
        console.error('Hata:', error)
        throw error // Hata oluştuğunda promise'ı reddet
      }
    }

    console.log('Tüm istekler tamamlandı')
  } catch (error) {
    console.error('Bir hata oluştu:', error)
  } finally {
    setSync('adminLoadAnim', false)
    setLive('orders', [])
    const orsd = await api.get('/getorders')
    console.log('ords', orsd)
    setLive('orders', orsd.data.orders)
  }
}
export const createCargo = async (keys) => {
  console.log('caego keys', keys)
  setSync('adminLoadAnim', true)
  try {
    // Tüm api.post isteklerini sırayla gerçekleştir
    const responses = await Promise.all(
      keys.map(async (key, index) => {
        try {
          await new Promise(resolve => setTimeout(resolve, index * 1000))
          const response = await api.post('/givecargo', { id: key })
          console.log('response', response)
          if (response?.data?.outResult) {
            setSync('adminLoadMsg', `${index + 1} numaralı kargo ${response?.data?.outResult?._text}`)
          }
          return response
        } catch (error) {
          console.error('Hata:', error)
          throw error // Hata oluştuğunda promise'ı reddet
        }
      })
    )
    console.log('Tüm istekler tamamlandı', responses)
  } catch (error) {
    console.error('Bir hata oluştu:', error)
  } finally {
    setSync('adminLoadAnim', false)
    setLive('orders', [])
    const orsd = await api.get('/getorders')
    console.log('ords', orsd)
    setLive('orders', orsd.data.orders)
  }
}

export const setMultiSube = async (keys) => {
  setSync('adminLoadAnim', true)
  try {
    const resp = await api.post('/setmultisube', { keys })
    console.log('response', resp.data)
    setSync('adminLoadAnim', false)
    setLive('orders', [])
    const orsd = await api.get('/getorders')
    console.log('ords', orsd)
    setLive('orders', orsd.data.orders)
  } catch (err) {
    console.log(err)
  }
}
export const createCargoPTT = async (keys) => {
  console.log('caego keys', keys)
  setSync('adminLoadAnim', true)
  try {
    // Tüm api.post isteklerini sırayla gerçekleştir
    const responses = await Promise.all(
      keys.map(async (key, index) => {
        try {
          await new Promise(resolve => setTimeout(resolve, index * 1000))
          const response = await api.post('/givecargoptt', { id: key })
          console.log('response', response)
          if (response?.data) {
            setSync('adminLoadMsg', `${index + 1} numaralı kargo oluşturuldu`)
          }
          return response
        } catch (error) {
          console.error('Hata:', error)
          throw error // Hata oluştuğunda promise'ı reddet
        }
      })
    )
    console.log('Tüm istekler tamamlandı', responses)
  } catch (error) {
    console.error('Bir hata oluştu:', error)
  } finally {
    setSync('adminLoadAnim', false)
    setLive('orders', [])
    const orsd = await api.get('/getorders')
    console.log('ords', orsd)
    setLive('orders', orsd.data.orders)
  }
}
export const createCargoAras = async (keys) => {
  console.log('caego keys', keys)
  setSync('adminLoadAnim', true)
  try {
    // Tüm api.post isteklerini sırayla gerçekleştir
    const responses = await Promise.all(
      keys.map(async (key, index) => {
        try {
          await new Promise(resolve => setTimeout(resolve, index * 1000))
          const response = await api.post('/givecargoaras', { id: key })
          console.log('response', response)
          if (response?.data) {
            setSync('adminLoadMsg', `${index + 1} numaralı kargo oluşturuldu`)
          }
          return response
        } catch (error) {
          console.error('Hata:', error)
          throw error // Hata oluştuğunda promise'ı reddet
        }
      })
    )
    console.log('Tüm istekler tamamlandı', responses)
  } catch (error) {
    console.error('Bir hata oluştu:', error)
  } finally {
    setSync('adminLoadAnim', false)
    setLive('orders', [])
    const orsd = await api.get('/getorders')
    console.log('ords', orsd)
    setLive('orders', orsd.data.orders)
  }
}
export const setLevel = async (orderID, level) => {
  try {
    const leveledOrder = await api.post('setlevel', { orderID, level })
    console.log('orders before leveled-->', getLive('orders'))
    console.log('leveled order -->', leveledOrder.data)

    const orders = [...getLive('orders')]
    const orderIndex = orders.findIndex(({ _id }) => _id === orderID)
    orders[orderIndex] = leveledOrder.data.order

    setLive('orders', [])
    setLive('orders', orders)

    console.log('orders after leveled-->', getLive('orders'))
    return true
  } catch (err) {
    console.log('leveledErr', err)
    return false
  }
}
export const deleteOrder = (orderId) => {
  api
    .post('/deleteorder', { orderId })
    .then((deletedorder) => {
      console.log('orders before leveled-->', getLive('orders'))
      console.log('deleted order', deletedorder)
      const orders = [...getLive('orders')]
      const orderIndex = orders.findIndex(({ _id }) => _id === orderId)
      orders.splice(orderIndex, 1)
      setLive('orders', [])
      setLive('orders', orders)
      console.log('orders after leveled-->', getLive('orders'))
    })
}
export const deleteCategory = (categoryId) => {
  api
    .post('/deletecategory', { categoryId })
    .then((deletedCategory) => {
      console.log('deletedCategory', deletedCategory)
    })
  const categories = [...getLive('categories')]
  const categoyIndex = categories.findIndex(({ _id }) => _id === categoryId)
  categories.splice(categoyIndex, 1)
  setLive('categories', [])
  setLive('categories', categories)
}
export const saveSortedCatories = data => api.post('/savesortedcategories', data).then((sorted) => {
  console.log('sorted result', sorted.data.category)
})
export const saveSortedProducts = data => api.post('/savesortedproducts', data).then((sorted) => {
  console.log('sorted result ', sorted.data.product)
})
export const setstocked = (data) => {
  api.post('/setstocked', data).then((stokced) => {
    const { product } = stokced.data
    const products = [...getLive('products')]
    console.log('after stoced products.json', products)
    const index = products.findIndex(p => p._id === product._id)
    products.splice(index, 1, product)
    setLive('products', products)
  })
}
export const setStockRate = (data) => {
  api.post('/setstockrate', data)
    .then((stokced) => {
      const { product } = stokced.data
      const products = [...getLive('products')]
      console.log('after stoced product: ', product)
      const index = products.findIndex(p => p._id === product._id)
      products.splice(index, 1, product)
      setLive('products', products)
    })
}
export const startEdit = (order) => {
  console.log('order: ', order)
  const fakeBasket = []
  // eslint-disable-next-line array-callback-return
  order?.products?.map((e) => {
    const fakeElement = { ...e }
    // eslint-disable-next-line no-underscore-dangle
    const element = getLive('products').filter(p => p._id === e._id)
    fakeElement.photos = element[0]?.photos
    fakeBasket.push(fakeElement)
  })
  const customerObj = { ...order?.delivery }
  const adress = customerObj?.adress
  customerObj.il = adress?.city
  customerObj.ilce = adress?.district
  customerObj.mahalle = adress?.neighborhood
  customerObj.fulladress = adress?.adressDetail
  customerObj.not = order?.not
  customerObj.phone = order?.delivery?.phone?.substring(1, order?.delivery?.phone?.length)
  setSync('basket', [])
  setSync('basket', fakeBasket)
  setStorage('baskets', [])
  setStorage('baskets', fakeBasket)
  setStorage('customer', customerObj)
  Router.pushRoute('/')
}
export const getcustomers = () => api.get('/getcustomers').then(({ data }) => data?.customers)
export const getallorders = () => api.get('/getallorders').then(({ data }) => data)
export const AdminActions = {
  getCategories,
  addCategory,
  UploadFiles,
  getOrders
}
